import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import Modal from 'react-modal';

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class FilterModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content : {
                border: 'none',
                backgroundColor: 'unset',
                height: 'auto',
                overflow: 'visable',
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.78)",
            }
        }
    }
    
    componentWillMount() {
        Modal.setAppElement('body');
    }

    renderTag = (tag, idx) => {
        const { addFilteredTag, filteredTags, shopsData } = this.props.myShopApiStore;
        const isFiltered = filteredTags.some(t => t.id === tag.id);

        const color = shopsData?.startColor?'#'+shopsData?.startColor:process.env.REACT_APP_START_COLOR;
        return(
            <div className={style.Tag}
            key={tag.id} 
            onClick={() => addFilteredTag(tag)}
            // style={{color: isFiltered ? '#00b1e1' : '#FFFF',
            style={{color: isFiltered ? color : '#FFFF',
                    backgroundColor: isFiltered ? '#FFFF' : 'unset'}}>
                <span>{tag.name}</span>
            </div>
        )
    }

    handleConfirmBtn = () => {
        const { shopsData, filteredTags, setFilteredShops } = this.props.myShopApiStore;
        const { shops } = shopsData;
        const filteredShops = [];
        shops.forEach(shop => {
            const { shopTags } = shop;
            for(let i=0 ; i<shopTags.length ; i++){
                const match = filteredTags.some(t => t.id === parseInt(shopTags[i]));
                if(match){
                    filteredShops.push(shop);
                    break;
                }
            }
        })
        setFilteredShops(filteredShops);
        this.props.closeModal();
    }

    render() {
        const { modalIsOpen } = this.props;
        const { shopsData } = this.props.myShopApiStore;

        return (
            (()=>{
                let color = shopsData?.startColor?'#'+shopsData?.startColor:process.env.REACT_APP_START_COLOR;
                document.querySelector(':root').style
                    .setProperty('--start-color', color);
            })(),
            <Modal style={this.modalStyle} isOpen={modalIsOpen} >
                <div className={style.ModalContainer}>
                    {
                        shopsData && 
                        <div className={style.Tags}>
                            {shopsData.tags.map(this.renderTag)}
                        </div>
                    }

                    <div className={style.ConfirmBtn}>
                        <button onClick={this.handleConfirmBtn}>אישור</button>
                    </div>

                </div>
            </Modal>
        )
    }
}

export default FilterModal
